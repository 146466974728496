import { ReactNode, forwardRef, memo } from "react"

import { useAppContext } from "@spatialsys/web/app-context"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LoadingSplash } from "@spatialsys/web/space/components/loading-splash/loading-splash"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SpaceContainerOutPortal } from "@spatialsys/web/space/space-container-portals"
import { Container } from "@spatialsys/web/ui"

import classes from "./homepage.module.scss"

type FeaturedPlayerProps = {
  closedFallback: ReactNode
}

export const FeaturedPlayer = memo(
  forwardRef<HTMLDivElement, FeaturedPlayerProps>(function FeaturedPlayer(props, ref) {
    const mode = useAppContext((context) => context.state.canvas.mode)
    const spaceId = useAppContext((context) => context.state.space.id)

    return (
      <Container ref={ref}>
        <div
          className={`relative mt-1 h-[57vh] max-h-[550px] min-h-[420px] overflow-hidden rounded-xl bg-muted ${classes.featuredBg}`}
        >
          {(() => {
            // Have to check spaceId to prevent a flash to the closed fallback when transitioning
            // from standalone-page to featured.
            if (mode === "closed" && !spaceId) {
              return props.closedFallback
            }
            if (mode === "featured") {
              return (
                <>
                  <SpaceContainerOutPortal canvasClassName="rounded-xl" />
                  <LoadingSplash />
                </>
              )
            }
            return null
          })()}
        </div>
      </Container>
    )
  })
)
