import { animate, m } from "framer-motion"
import { forwardRef, memo, useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"

import MetricsPosterImage from "@spatialsys/assets/img/rebranding/metrics/metrics-poster.webp"
import { TrackedComponent, TrackedComponents } from "@spatialsys/react/analytics"
import { useBoolean } from "@spatialsys/react/hooks/use-boolean"
import Config from "@spatialsys/web/config"
import { Heading, cn } from "@spatialsys/web/ui"

import { useLocale } from "../../homepage"

const VIDEO_PATH_WEBM = "homepage/videos/metrics.webm"
const VIDEO_PATH_MP4 = "homepage/videos/metrics.mp4"

type MetricsProps = {
  inView: boolean
}

export const Metrics = memo(
  forwardRef<HTMLDivElement, MetricsProps>(function Metrics({ inView }, ref) {
    const locale = useLocale()
    const isKorean = locale === "ko" || locale === "ko-KR"

    const [isCounterInView, setIsCounterInView] = useState<boolean | null>(null)
    const [shouldAnimateCounter, setShouldAnimateCounter] = useBoolean(false)

    const videoRef = useRef<HTMLVideoElement>(null)

    const [counterRef] = useInView({
      threshold: 0.1,
      onChange: (inView) => setIsCounterInView(inView),
    })

    /** Play the video if the video component is mounted and in view */
    useEffect(() => {
      if (videoRef.current) {
        if (inView) {
          videoRef.current.play().catch(() => {})
        } else {
          videoRef.current.pause()
        }
      }
    }, [inView])

    useEffect(() => {
      if (isCounterInView) {
        setShouldAnimateCounter.setTrue()
      }
    }, [isCounterInView, setShouldAnimateCounter])

    return (
      <section ref={ref} className="h-[100vh] max-h-[480px] overflow-hidden">
        <TrackedComponent id={TrackedComponents.HeaderSection} as="div" className="relative h-full">
          <div className="h-full">
            <div className="grid h-full auto-cols-[100%] grid-flow-col">
              <div className="absolute inset-0 z-10 bg-background/60" />
              <video
                ref={videoRef}
                className="absolute inset-0 z-0 size-full object-cover blur-xl"
                poster={MetricsPosterImage.src}
                disablePictureInPicture
                disableRemotePlayback
                muted
                playsInline
                autoPlay
                loop
              >
                <source type="video/webm" src={`${Config.PUBLIC_ASSETS_BASE_URL}/${VIDEO_PATH_WEBM}`} />
                <source type="video/mp4" src={`${Config.PUBLIC_ASSETS_BASE_URL}/${VIDEO_PATH_MP4}`} />
              </video>
            </div>
            <m.div
              ref={counterRef}
              className="absolute inset-0 z-10 flex flex-col items-center justify-center gap-4 text-center sm:flex-row sm:gap-8 md:gap-16"
              initial={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true, margin: "0px 0px -180px 0px" }}
            >
              <div>
                <Heading weight="bold" className="text-[48px] md:text-[64px] lg:text-[72px]">
                  <Counter from={0} to={2.5} shouldAnimateCounter={shouldAnimateCounter} />
                  M+
                </Heading>
                <Heading
                  className={cn(
                    "text-sm font-demibold text-foreground/50 md:text-base lg:text-lg",
                    isKorean && "font-kr font-bold"
                  )}
                >
                  {isKorean ? "3D 월드 개수" : "Total User generated 3D spaces"}
                </Heading>
              </div>
              <div>
                <Heading weight="bold" className="text-[48px] md:text-[64px] lg:text-[72px]">
                  <Counter from={0} to={32} decimals={0} shouldAnimateCounter={shouldAnimateCounter} />
                  M+
                </Heading>
                <Heading
                  className={cn(
                    "text-sm font-demibold text-foreground/50 md:text-base lg:text-lg",
                    isKorean && "font-kr font-bold"
                  )}
                >
                  {isKorean ? "연간 사용자 수" : "Unique Users (Last 12 Months)"}
                </Heading>
              </div>
              <div>
                <Heading weight="bold" className="text-[48px] md:text-[64px] lg:text-[72px]">
                  <Counter from={0} to={476} decimals={0} shouldAnimateCounter={shouldAnimateCounter} />
                  M+
                </Heading>
                <Heading
                  className={cn(
                    "text-sm font-demibold text-foreground/50 md:text-base lg:text-lg",
                    isKorean && "font-kr font-bold"
                  )}
                >
                  {isKorean ? "총 참여 시간" : "Total Engagement Hours"}
                </Heading>
              </div>
              <div>
                <Heading weight="bold" className="text-[48px] md:text-[64px] lg:text-[72px]">
                  <Counter from={0} to={80} decimals={0} shouldAnimateCounter={shouldAnimateCounter} />
                  %+
                </Heading>
                <Heading
                  className={cn(
                    "text-sm font-demibold text-foreground/50 md:text-base lg:text-lg",
                    isKorean && "font-kr font-bold"
                  )}
                >
                  {isKorean ? "21세 이상 사용자 수" : "Over 21 Audience"}
                </Heading>
              </div>
            </m.div>
          </div>
        </TrackedComponent>
      </section>
    )
  })
)

function Counter({
  from,
  to,
  decimals = 1,
  shouldAnimateCounter,
}: {
  from: number
  to: number
  decimals?: number
  shouldAnimateCounter: boolean
}) {
  const nodeRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (!shouldAnimateCounter) return

    const node = nodeRef.current

    const controls = animate(from, to, {
      ease: "easeInOut",
      duration: 1.2,
      onUpdate(value) {
        if (node) {
          node.textContent = value.toFixed(decimals)
        }
      },
    })

    return () => controls.stop()
  }, [from, to, decimals, shouldAnimateCounter])

  return <span ref={nodeRef} />
}
