import clsx from "clsx"
import { forwardRef } from "react"

import classes from "./generic-error.module.scss"

export type GenericErrorProps = React.ComponentPropsWithoutRef<"div">

export const GenericError = forwardRef<HTMLDivElement, GenericErrorProps>((props, ref) => {
  const { className, ...rest } = props

  return (
    <div ref={ref} className={clsx(classes.container, className)} {...rest}>
      Sorry, something went wrong. Please try again later
    </div>
  )
})
