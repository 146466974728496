import { Skeleton } from "@spatialsys/web/ui"

import spacesGridClasses from "../../spaces-feed/spaces-grid/spaces-grid.module.scss"
import classes from "./spaces-list-grid-loading-skeleton.module.scss"

const NUM_ITEMS_IN_LOADER = 20
const loadingItemsArr = new Array(NUM_ITEMS_IN_LOADER).fill(0)

export const SpacesListGridLoadingSkeleton = () => {
  return (
    <div className={spacesGridClasses.gridContainer}>
      {loadingItemsArr.map((_, index) => (
        <div key={index} className={classes.loadingItem}>
          <Skeleton className={classes.skeletonImage} />
          <div className={classes.infoRow}>
            <Skeleton className="isolate h-12 w-12 overflow-hidden rounded-full" />
            <div className={classes.textContainer}>
              <Skeleton />
              <Skeleton />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
