import { memo, useCallback, useMemo } from "react"

import { abbreviateNumber } from "@spatialsys/js/util/format-numbers"
import { InteractionName, InteractionType, useTrackInteraction } from "@spatialsys/react/analytics"
import { useSetSpaceLovedMutation } from "@spatialsys/react/query-hooks/spaces"
import { useAppContext, useAuthState } from "@spatialsys/web/app-context"
import { PushNotificationPermissionModalType } from "@spatialsys/web/app-state"
import { cn, toast } from "@spatialsys/web/ui"

import { LoveSpaceIcon } from "./love-space-icon"

export type LoveSpaceButtonProps = {
  classNameContainer?: string
  classNameIcon?: string
  classNameText?: string
  handleUnauthenticatedClick?: () => void
  hideLoveCount?: boolean
  isDisabled?: boolean
  isLoved?: boolean
  loveCount: number
  spaceId: string
}

/**
 * Renders a heart icon and love count. Clicking on the button will love/unlove the space.
 * The icon and text inherit font size by default
 */
export const LoveSpaceButton = memo(function LoveSpaceButton(props: LoveSpaceButtonProps) {
  const {
    classNameContainer,
    classNameIcon,
    classNameText,
    handleUnauthenticatedClick,
    hideLoveCount,
    isDisabled,
    isLoved,
    loveCount,
    spaceId,
  } = props

  const { isAuthenticated, isAuthless } = useAuthState()
  const setSpaceLovedMutation = useSetSpaceLovedMutation()
  const trackInteraction = useTrackInteraction()
  const actions = useAppContext((context) => context.actions)

  const loveCountText = useMemo(() => {
    if (loveCount === 0 || hideLoveCount) {
      return undefined
    }
    return abbreviateNumber(loveCount, 1)
  }, [hideLoveCount, loveCount])

  const handleSetSpaceLoved = useCallback(() => {
    const newLoveState = !isLoved
    setSpaceLovedMutation.mutate(
      { spaceId, isLoved: newLoveState },
      {
        onError: () =>
          toast.error(`Error occurred when ${newLoveState ? "loving" : "unloving"} space. Please try again later`),
      }
    )
    trackInteraction(
      {
        name: newLoveState ? InteractionName.LoveSpace : InteractionName.UnloveSpace,
        type: InteractionType.Click,
      },
      {
        "Room ID": spaceId,
      }
    )
    if (newLoveState) {
      actions.requestPushNotificationPermission(PushNotificationPermissionModalType.LoveSpace)
    }
  }, [actions, isLoved, setSpaceLovedMutation, spaceId, trackInteraction])

  return (
    <button
      aria-label="Love this space"
      className={cn(
        "group pointer-events-auto flex items-center space-x-1 text-white",
        isDisabled && "pointer-events-none",
        classNameContainer
      )}
      disabled={isDisabled}
      onClick={(e) => {
        // Prevent click event of parent button from executing
        e.preventDefault()
        // Also stop propagation to parent elements (e.g. portal destination picker modal button events that preventDefault() fails to stop)
        e.stopPropagation()
        if (!isAuthenticated || isAuthless) {
          handleUnauthenticatedClick?.()
        } else {
          handleSetSpaceLoved()
        }
      }}
    >
      <LoveSpaceIcon isLoved={isLoved} loveCount={loveCount} isDisabled={isDisabled} className={classNameIcon} />
      {loveCountText && <span className={classNameText}>{loveCountText}</span>}
    </button>
  )
})
