import Image from "next/image"
import Link from "next/link"
import { memo, useCallback, useMemo } from "react"

import { FeaturedCarouselItem } from "@spatialsys/js/sapi/spaces/spaces"
import {
  InteractionName,
  InteractionType,
  TrackedComponents,
  useTrackInteraction,
  withTrackedComponent,
} from "@spatialsys/react/analytics"
import { RoomJoinMethod } from "@spatialsys/unity/app-state"
import { SpaceJoinContextSource, createSpaceClickEvent, createSpaceMetadataProperties } from "@spatialsys/unity/bridge"
import { formatSpacePath } from "@spatialsys/url-utils"
import { useAppContext, useAuthState } from "@spatialsys/web/app-context"
import { LoveSpaceButton } from "@spatialsys/web/core/js/components/love-space-button/love-space-button"
import { LoveCount } from "@spatialsys/web/core/js/components/social-signals/love-count"
import { ViewCount } from "@spatialsys/web/core/js/components/social-signals/view-count"
import { LiveBadge } from "@spatialsys/web/core/js/components/space-badges/live-badge"
import { spatialCdnImageLoader } from "@spatialsys/web/core/js/util/next-image-loader"
import { Button, Container, Heading, Text } from "@spatialsys/web/ui"

import { HeroCarouselVideo, HeroCarouselVideoProps } from "../hero-carousel-video/hero-carousel-video"

type SlideItemProps = Pick<HeroCarouselVideoProps, "loadFirstFrameImage" | "onVideoEnded"> & {
  isActive: boolean
  index: number
  slide: FeaturedCarouselItem & { isLoved?: boolean }
  isAutoplayActive: boolean
  openLoginModal: () => void
}

const SPATIAL_URL = "spatial.io"
const WWW_SPATIAL_URL = "www.spatial.io"

/**
 * A single slide item in the hero carousel
 */
export const HeroCarouselSlide = withTrackedComponent(
  memo(function HeroCarouselSlide(props: SlideItemProps) {
    const { index, isActive, slide, isAutoplayActive, openLoginModal, ...rest } = props
    const { isAuthenticatingOrLoggingIn } = useAuthState()
    const trackInteraction = useTrackInteraction()
    const actions = useAppContext((context) => context.actions)

    const ctaUrl = useMemo(() => {
      if (slide.ctaUrl) {
        // Use client-side routing for spatial.io links
        const url = new URL(slide.ctaUrl)
        if (url.hostname === SPATIAL_URL || url.hostname === WWW_SPATIAL_URL) {
          return url.pathname + url.search
        }
        // cta URL always takes precedence, even if space is defined
        return slide.ctaUrl
      }
      if (slide.space) {
        return formatSpacePath({ id: slide.space.id, slug: slide.space.slug, shareId: slide.space.shareID })
      }

      // Return some default to satisfy TS.
      // In reality, this is never reached because we enforce one of `ctaUrl` or `space` to be defined,
      return "https://www.spatial.io"
    }, [slide.ctaUrl, slide.space])

    const trackLinkClick = useCallback(() => {
      trackInteraction({ type: InteractionType.Click, name: InteractionName.HomepageHeroCta })
      if (slide.space?.id) {
        const { space } = slide
        const spaceProperties = createSpaceMetadataProperties(space)
        actions.setSpaceJoinContext({
          method: RoomJoinMethod.UserSelected,
          spaceProperties,
          discoveryMetadata: { Source: SpaceJoinContextSource.HeroCarousel, "Space Index": index },
        })
        trackInteraction(
          ...createSpaceClickEvent({
            ...spaceProperties,
            "Space Index": index,
            Source: SpaceJoinContextSource.HeroCarousel,
          })
        )
      }
    }, [actions, index, slide, trackInteraction])

    const trackCreatorClick = useCallback(() => {
      trackInteraction({ type: InteractionType.Click, name: InteractionName.HomepageHeroCreator })
    }, [trackInteraction])

    return (
      <div className="relative flex h-full w-full flex-col justify-end bg-black mobile:justify-center">
        <HeroCarouselVideo
          title={slide.title}
          srcMp4={slide.video.mp4}
          srcWebm={slide.video.webm}
          shouldPlay={isActive}
          isAutoplayActive={isAutoplayActive}
          firstFrameImgSrc={slide.video.poster}
          {...rest}
        />
        <Container className="z-10 grid px-5 py-8">
          <div className="flex flex-col gap-2 text-white text-shadow-sm mobile:gap-5">
            {slide.space && <LiveBadge activeUserCount={slide.space.activeUserCount} isLive={slide.space.live} />}
            {slide.space?.logo ? (
              <>
                {slide.space?.logo && (
                  <Image
                    width={416}
                    height={160}
                    src={slide.space?.logo}
                    alt={`${slide.space?.name} logo`}
                    className="max-h-24 max-w-[70%] object-contain object-left mobile:max-h-40 mobile:max-w-[26rem]"
                    loader={spatialCdnImageLoader}
                  />
                )}
                {/* This is for SEO purpose. Even if there is no logo, we should have this hidden `h1` for screen reader. */}
                <p className="sr-only">{slide.space?.name ?? slide.title}</p>
              </>
            ) : (
              <Heading
                className="-mb-0.5 max-w-[75vw] text-m3 font-black text-white sm:-mb-1 sm:text-m2 lg:text-m1"
                asChild
              >
                <p>{slide.space?.name ?? slide.title}</p>
              </Heading>
            )}
            <div className="grid gap-0.5 mobile:p-0 sm:gap-2">
              {slide.creator && (
                <div className="flex gap-1.5">
                  <Text size="sm" className="font-heading font-demibold md:text-base">
                    <span>By </span>
                    <Link
                      href={`/@${slide.creator.username}`}
                      className="w-fit text-white no-underline transition-opacity hover:opacity-80"
                      onClick={trackCreatorClick}
                    >
                      {slide.creator.displayName}
                    </Link>
                  </Text>
                </div>
              )}
              <Text
                size="xs"
                className="hidden w-[90vw] min-w-[200px] max-w-[1000px] mobile:block mobile:w-[60vw] xs:w-[40vw] xs:max-w-[500px] sm:max-w-[720px] sm:text-sm lg:text-base"
              >
                {slide.description}
              </Text>
            </div>
            <div className="grid grid-flow-col items-center justify-start gap-2 mobile:gap-6">
              <Button
                as={Link}
                color="blue"
                href={ctaUrl}
                className="gap-2 px-6 text-shadow-none mobile:h-12 mobile:px-10"
                onClick={trackLinkClick}
              >
                <Heading weight="bold" className="text-sm mobile:text-base">
                  {slide.ctaTitle}
                </Heading>
              </Button>
              {slide.space && (
                <LoveSpaceButton
                  classNameContainer="w-10 h-10 mobile:w-12 mobile:h-12 justify-center hover:bg-black rounded-full bg-black/80"
                  classNameIcon="icon-xs mobile:icon-sm"
                  handleUnauthenticatedClick={openLoginModal}
                  isDisabled={isAuthenticatingOrLoggingIn}
                  isLoved={slide.isLoved}
                  loveCount={slide.space.likeCount}
                  spaceId={slide.space.id}
                  hideLoveCount
                />
              )}
              {slide.badge && (
                <div className="flex items-center">
                  <span className="hidden text-sm md:inline">
                    Earn <span className="font-bold">{slide.badge.name} Badge</span>{" "}
                  </span>
                  <img className="ml-2 h-8 w-8 rounded-lg" src={slide.badge.badgeIconURL} alt={slide.badge.name} />
                </div>
              )}
              {slide.space && !slide.badge && (
                <div className="text-sm font-demibold drop-shadow-lg">
                  <ViewCount numViews={slide.space.joinCount} />
                  <span className="px-[3px]">•</span>
                  <LoveCount numLoves={slide.space.likeCount} />
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    )
  }),
  ({ slide }) => {
    return {
      id: TrackedComponents.HeroCarouselSlide,
      properties: { "Slide ID": slide.id, "Slide Position": slide.position },
      viewOptions: {
        threshold: 1,
        delay: 100, // slight delay so skipping slides doesn't count as views on skipped
      },
    }
  }
)
