import { Tail } from "@spatialsys/js/types"
import { InteractionName, InteractionType, Properties, trackInteraction } from "@spatialsys/react/analytics"

import type { SourcedSpaceItemClickMetadata, SpaceJoinContextSpaceMetadata } from "./types"

type SpaceClickContext = SourcedSpaceItemClickMetadata &
  SpaceJoinContextSpaceMetadata & {
    "Is External Game"?: boolean
  }

export function createSpaceClickEvent(
  context: SpaceClickContext,
  additionalProperties?: Properties
): Tail<Parameters<typeof trackInteraction>> {
  const res = Object.entries(context).reduce(
    (acc, [key, value]) => {
      return {
        ...acc,
        [`SCC - ${key}`]: value,
      }
    },
    {} as Record<string, any>
  )
  return [
    { name: InteractionName.SpaceTile, type: InteractionType.Click },
    { ...res, ...additionalProperties },
  ]
}
