import useEmblaCarousel from "embla-carousel-react"
import { range } from "lodash"
import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from "react"

import { notEmpty } from "@spatialsys/js/util/type-helpers"
import { TrackedComponent, TrackedComponents } from "@spatialsys/react/analytics"
import { useBoolean } from "@spatialsys/react/hooks/use-boolean"
import { useGetFeaturedCarouselQuery, useGetLovedSpacesQuery } from "@spatialsys/react/query-hooks/spaces"
import { useAppContext, useAuthState } from "@spatialsys/web/app-context"
import { Modals } from "@spatialsys/web/app-state"
import { Skeleton, cn } from "@spatialsys/web/ui"

import { HeroCarouselSlide } from "./hero-carousel-slide/hero-carousel-slide"

type HeroCarouselProps = {
  inView: boolean
}

export const HeroCarousel = memo(
  forwardRef<HTMLDivElement, HeroCarouselProps>(function HeroCarousel(props, ref) {
    const getFeaturedCarouselQuery = useGetFeaturedCarouselQuery()
    const { isAuthenticated, isAuthless } = useAuthState()

    const getLovedSpacesQuery = useGetLovedSpacesQuery(
      {
        spaceIds: getFeaturedCarouselQuery.data?.map((item) => item.space?.id).filter(notEmpty) ?? [],
      },
      {
        enabled: isAuthenticated && !isAuthless && Boolean(getFeaturedCarouselQuery.data),
        staleTime: 60 * 1000,
      }
    )

    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })

    const [selectedIndex, setSelectedIndex] = useState(0)
    const [isAutoplayActive, setIsAutoplayActive] = useBoolean(true)
    const [isAutoplayManuallyDisabled, setIsAutoplayManuallyDisabled] = useBoolean(false)
    const actions = useAppContext((context) => context.actions)
    const openLoginModal = useCallback(
      () => actions.openModal({ type: Modals.Login, payload: { titleCta: "Sign in to continue" } }),
      [actions]
    )

    const slidesData = useMemo(() => {
      // If we don't have data yet, return undefined
      if (!getFeaturedCarouselQuery.data) {
        return
      }
      // If we're still fetching loved spaces, just return the data
      if (!getLovedSpacesQuery.data) {
        return getFeaturedCarouselQuery.data
      }

      return getFeaturedCarouselQuery.data.map((item) => {
        if (!item.space) {
          return item
        }
        return { ...item, isLoved: getLovedSpacesQuery.data.spacesLoved[item.space.id] }
      })
    }, [getFeaturedCarouselQuery.data, getLovedSpacesQuery.data])

    /** Register Embla event listeners */
    useEffect(() => {
      if (!emblaApi) return

      /** Synchronizes `selectedIndex` with the currently active carousel slide. */
      const onSelect = () => {
        setSelectedIndex(emblaApi.selectedScrollSnap())
      }

      const onPointerUp = () => {
        const nextIndex = emblaApi.selectedScrollSnap()

        // If user has manually disabled autoplay, never re-enable it
        if (isAutoplayManuallyDisabled) {
          return
        }

        if (nextIndex !== selectedIndex) {
          // If user changed the slide, disable autoplay forever
          setIsAutoplayManuallyDisabled.setTrue()
        } else {
          // User did not change the slide, re-enable autoplay if it hasn't been manually disabled
          if (!isAutoplayManuallyDisabled) {
            setIsAutoplayActive.setTrue()
          }
        }
      }

      onSelect()
      emblaApi.on("select", onSelect)
      /** Disable autoplay when the user interacts with the carousel. */
      emblaApi.on("pointerDown", setIsAutoplayActive.setFalse)
      emblaApi.on("pointerUp", onPointerUp)

      return () => {
        emblaApi.off("select", onSelect)
        emblaApi.off("pointerDown", setIsAutoplayActive.setFalse)
        emblaApi.off("pointerUp", onPointerUp)
      }
    }, [emblaApi, isAutoplayManuallyDisabled, selectedIndex, setIsAutoplayActive, setIsAutoplayManuallyDisabled])

    const handleClickDot = useCallback(
      (idx: number) => {
        emblaApi?.scrollTo(idx)
        setIsAutoplayActive.setFalse()
        setIsAutoplayManuallyDisabled.setTrue()
      },
      [emblaApi, setIsAutoplayActive, setIsAutoplayManuallyDisabled]
    )

    /**
     * If autoplay is active, scroll to the next slide when the active slide's video loops
     */
    const handleVideoEnded = useCallback(
      (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        if (isAutoplayActive) {
          emblaApi?.scrollNext()
        } else {
          // Manually loop
          void e.currentTarget.play().catch(() => {})
        }
      },
      [emblaApi, isAutoplayActive]
    )

    /**
     * Maps the carousel slides data to UI components
     */
    const slideElements = useMemo(() => {
      if (!slidesData) return []

      return slidesData.map((slideData, i) => {
        const isActive = selectedIndex === i
        const isActivePreviousOrNext =
          isActive ||
          i === (selectedIndex + 1) % slidesData.length ||
          i === (selectedIndex + slidesData.length - 1) % slidesData.length
        return (
          <HeroCarouselSlide
            key={slideData.id}
            index={i}
            onVideoEnded={handleVideoEnded}
            isActive={isActive}
            loadFirstFrameImage={isActivePreviousOrNext}
            isAutoplayActive={isAutoplayActive}
            openLoginModal={openLoginModal}
            slide={slideData}
          />
        )
      })
    }, [handleVideoEnded, isAutoplayActive, openLoginModal, selectedIndex, slidesData])

    /**
     * When the slides change, we must re-initialize Embla
     * https://www.embla-carousel.com/api/methods/#reinit
     */
    useEffect(() => {
      if (slideElements.length) {
        emblaApi?.reInit()
      }
    }, [emblaApi, slideElements.length])

    useEffect(() => {
      if (slideElements.length < 2) {
        setIsAutoplayActive.setFalse()
      } else {
        setIsAutoplayActive.set(props.inView)
      }
    }, [props.inView, setIsAutoplayActive, slideElements.length])

    return (
      <section ref={ref} className="h-full w-full">
        <TrackedComponent id={TrackedComponents.HeroCarousel} as="div" className="relative h-full">
          <div className="h-full overflow-hidden" ref={slideElements.length > 1 ? emblaRef : undefined}>
            <div className="grid h-full auto-cols-[100%] grid-flow-col">
              {slideElements.length ? slideElements : <Skeleton />}
            </div>
          </div>

          {getFeaturedCarouselQuery.data && slideElements.length > 1 && (
            <div className="absolute bottom-6 hidden grid-flow-col justify-center gap-2 absolute-center-x mobile:grid">
              {range(0, slideElements.length).map((idx) => {
                return (
                  <button
                    key={idx}
                    aria-label={`Jump to slide ${idx}`}
                    aria-current={idx === selectedIndex ? "true" : undefined}
                    className={cn(
                      "h-2 w-2 rounded-full border border-solid border-muted-foreground bg-transparent transition-colors duration-150 md:h-2.5 md:w-2.5",
                      "hover:border-foreground/80 hover:bg-foreground/80 aria-[current]:border-white aria-[current]:bg-white"
                    )}
                    onClick={() => handleClickDot(idx)}
                  />
                )
              })}
            </div>
          )}
        </TrackedComponent>
      </section>
    )
  })
)
