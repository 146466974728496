import { AnimatePresence, m } from "framer-motion"
import Link from "next/link"
import { memo, useCallback } from "react"

import { ReactComponent as UnityWordMark } from "@spatialsys/assets/icons/unity-wordmark.svg"
import BuildWithoutCodeImage from "@spatialsys/assets/img/rebranding/create-your-world/build-without-code.webp"
import UnityCompatibilityImage from "@spatialsys/assets/img/rebranding/create-your-world/unity-compatibility.webp"
import { useAppContext } from "@spatialsys/web/app-context"
import { Modals } from "@spatialsys/web/app-state"
import { Button, Heading, Text, cn } from "@spatialsys/web/ui"

import { useLocale } from "../../homepage"
import { SectionHeader } from "../../layouts/marketing/section/section-header"

type CreateYourWorldProps = {
  isSmallScreen: boolean
}

export const CreateYourWorld = memo(function CreateYourWorld({ isSmallScreen }: CreateYourWorldProps) {
  const actions = useAppContext((context) => context.actions)
  const locale = useLocale()
  const isKorean = locale === "ko" || locale === "ko-KR"

  const openLoginModal = useCallback(
    () => actions.openModal({ type: Modals.Login, payload: { titleCta: "Sign in to create a space!" } }),
    [actions]
  )

  return (
    <div className={isSmallScreen ? "py-[48px]" : "py-[72px]"}>
      <SectionHeader
        title={isKorean ? "나만의 세상, 나만의 스타일" : "Your World, Your Way"}
        titleClassName={isKorean ? "font-kr font-bold" : undefined}
        catchphrase={
          <>
            {isKorean ? "당신의 창의력을 펼칠 수 있도록" : "No Limits to Your Creativity"}
            <br />
            {isKorean ? "간편 3D 템플릿 제공과 커스텀 설정을 한번에" : "from 3D Templates to Full Customization"}
          </>
        }
        catchphraseClassName={isKorean ? "font-kr" : undefined}
      />
      <div
        className={cn(
          "mx-auto grid max-w-screen-2xl grid-cols-2 justify-between gap-16",
          isSmallScreen && "grid-cols-1"
        )}
      >
        <AnimatePresence>
          <div className="grid grid-rows-[minmax(395px,auto)_auto_1fr]">
            <m.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "0px 0px -180px 0px" }}
              transition={{ duration: 0.8 }}
              className="relative"
            >
              <img src={BuildWithoutCodeImage.src} alt="Build without code" className="size-full object-contain" />
              <div className="absolute inset-0 bg-gradient-to-t from-background from-[14%] to-transparent" />
            </m.div>
            <Heading className={cn("z-10 mb-[10px]", isSmallScreen ? "text-md" : "text-xl", isKorean && "font-kr")}>
              {isKorean ? "차세대 크리에이터" : "Next-Gen Creators"}
            </Heading>
            <Text
              className={cn(
                "z-10 mb-6 text-muted-foreground",
                isSmallScreen ? "text-md" : "text-lg",
                isKorean && "font-kr"
              )}
            >
              {isKorean ? (
                <>
                  스페이셜은 간편하게 배포 가능한{" "}
                  <span className="font-demibold text-foreground">프리미엄 멀티플레이어 템플릿</span>을 제공합니다. 코딩
                  없이도 수준 높은 아름다움을 뽐내는 인터랙티브 공간을 만들어보세요. 2D, 3D, 영상 콘텐츠를 업로드하여
                  웹, VR/AR, 모바일에 바로 공개할 수 있습니다.
                </>
              ) : (
                <>
                  Create stunning interactive spaces effortlessly using{" "}
                  <span className="font-demibold text-foreground">
                    Spatial's premium no-code multi-player templates
                  </span>
                  . Upload 2D, 3D, and video content, and publish directly to Web, VR/AR, and Mobile.
                </>
              )}
            </Text>
            <Button
              size="lg"
              className={cn("w-1/2 bg-popover text-sm", isSmallScreen && "w-full", isKorean && "font-kr")}
              noShadow
              noScaleOnHover={isSmallScreen}
              onClick={openLoginModal}
            >
              {isKorean ? "무료로 시작하기" : "Create for free"}
            </Button>
          </div>
          <div className="grid grid-rows-[minmax(395px,auto)_auto_1fr]">
            <m.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "0px 0px -180px 0px" }}
              transition={{ duration: 0.8, delay: isSmallScreen ? 0 : 0.5 }}
              className="relative"
            >
              <img src={UnityCompatibilityImage.src} alt="Unity compatibility" className="size-full object-contain" />
              <div className="absolute inset-0 bg-gradient-to-t from-background from-[14%] to-transparent" />
              <div className="absolute bottom-[22%] right-[4%] flex items-center justify-center rounded-xl bg-foreground/15 py-0 pl-3 pr-1 backdrop-blur-[1px] sm:py-0.5 sm:pl-4 sm:pr-2">
                <Text size={isSmallScreen ? "sm" : "lg"}>Powered by</Text>
                <UnityWordMark className="h-9 sm:h-10" />
              </div>
            </m.div>
            <Heading className={cn("z-10 mb-[10px]", isSmallScreen ? "text-md" : "text-xl", isKorean && "font-kr")}>
              {isKorean ? "전문 개발자" : "Advanced Developers"}
            </Heading>
            <Text className={cn("z-10 mb-6 text-muted-foreground", isSmallScreen ? "text-md" : "text-lg")}>
              {isKorean ? (
                <>
                  <span className="font-demibold text-foreground">스페이셜의 Unity SDK</span>를 활용하시면 가능한 모든
                  형태의 인터랙티브 세상을 맞춤 제작할 수 있습니다. 정교한 아트, 상호작용과 다양한 효과를 활용해보세요.
                  몰입감 넘치는 독특한 경험을 제작하여 웹, VR/AR, 모바일에 손쉽게 배포할 수 있습니다.
                </>
              ) : (
                <>
                  Build custom interactive worlds with{" "}
                  <span className="font-demibold text-foreground">Spatial’s Unity SDK</span>. Create immersive
                  experiences with advanced interactivity and physics, and publish seamlessly to Web, VR/AR, and Mobile.
                </>
              )}
            </Text>
            <Button
              size="lg"
              className={cn("w-1/2 bg-popover text-sm", isSmallScreen && "w-full", isKorean && "font-kr")}
              noShadow
              noScaleOnHover={isSmallScreen}
              as={Link}
              href="https://toolkit.spatial.io/docs"
              target="_blank"
            >
              {isKorean ? "Unity SDK 활용 가이드" : "See documentation"}
            </Button>
          </div>
        </AnimatePresence>
      </div>
    </div>
  )
})
