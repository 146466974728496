import { ReactNode, useCallback, useState } from "react"
import { createContext, useContextSelector } from "use-context-selector"

type VideoPlayingContext = {
  /**
   * First number is the item index, second is section index. (aka column then row).
   * sectionIndex only comes into play on the home feed. In other contexts where
   * there is just one list, there will be no sectionIndex.
   * Default value is `undefined` when there's no context, vs `null` when there is a
   * context but no active video.
   */
  activeVideo: [number, number?] | null | undefined
  setActiveVideo: (index: number, sectionIndex?: number) => void
  clearActiveVideo: () => void
}

const VideoPlayingContext = createContext<VideoPlayingContext>({
  activeVideo: undefined,
  setActiveVideo: () => {},
  clearActiveVideo: () => {},
})

export const VideoPlayingContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<VideoPlayingContext["activeVideo"]>(null)
  const setActiveVideo: VideoPlayingContext["setActiveVideo"] = useCallback((...args) => {
    setState(args)
  }, [])
  const clearActiveVideo = useCallback(() => {
    setState(null)
  }, [])
  return (
    <VideoPlayingContext.Provider value={{ activeVideo: state, setActiveVideo, clearActiveVideo }}>
      {children}
    </VideoPlayingContext.Provider>
  )
}

/**
 * @param selector if omitted, the whole context will be returned.
 */
export function useVideoPlayingContext<T = VideoPlayingContext>(
  selector: (context: VideoPlayingContext) => T = (x) => x as T
) {
  return useContextSelector(VideoPlayingContext, selector)
}
