import Head from "next/head"
import { memo } from "react"
import { Thing, WithContext } from "schema-dts"

type JsonLdProps = { jsonLd: WithContext<Thing> | WithContext<Thing>[] }

/**
 * json-ld script tag wrapped in a nextjs Head component
 *
 * Cannot be placed inside of a nextjs <Head> tag
 */
export const JsonLdInHead = memo(function JsonLdInHead(props: JsonLdProps) {
  const { jsonLd } = props

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} // google doesnt see this unless its dangerously set. see https://nextjs.org/docs/app/building-your-application/optimizing/metadata#json-ld
      />
    </Head>
  )
})
