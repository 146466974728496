import Link from "next/link"
import { forwardRef, memo, useCallback, useEffect, useRef } from "react"

import { ReactComponent as EmailIcon } from "@spatialsys/assets/icons/material-outlined/mail.svg"
import HeaderPosterImage from "@spatialsys/assets/img/rebranding/header/header-poster.webp"
import {
  InteractionName,
  InteractionType,
  TrackedComponent,
  TrackedComponents,
  useTrackInteraction,
} from "@spatialsys/react/analytics"
import { useAppContext } from "@spatialsys/web/app-context"
import Config from "@spatialsys/web/config"
import { Button, Heading, Text, cn } from "@spatialsys/web/ui"

import { useLocale } from "../homepage"

const VIDEO_PATH_WEBM = "homepage/videos/header.webm"
const VIDEO_PATH_MP4 = "homepage/videos/header.mp4"
type AuthlessHeaderSectionProps = {
  inView: boolean
  isSmallScreen: boolean
}

export const AuthlessHeaderSection = memo(
  forwardRef<HTMLDivElement, AuthlessHeaderSectionProps>(function AuthlessHeaderSection(
    { inView, isSmallScreen },
    ref
  ) {
    const trackInteraction = useTrackInteraction()
    const actions = useAppContext((context) => context.actions)
    const locale = useLocale()
    const isKorean = locale === "ko" || locale === "ko-KR"
    const showOldHomepage = useCallback(() => {
      actions.showOldHomepage()
    }, [actions])
    const videoRef = useRef<HTMLVideoElement>(null)

    /** Play the video if the video component is mounted and in view */
    useEffect(() => {
      if (videoRef.current) {
        if (inView) {
          videoRef.current.play().catch(() => {})
        } else {
          videoRef.current.pause()
        }
      }
    }, [inView])

    return (
      <section ref={ref} className="h-[100vh] max-h-[1280px] min-h-[480px]">
        <TrackedComponent id={TrackedComponents.HeaderSection} as="div" className="relative h-full">
          <div className="h-full overflow-hidden">
            <div className="grid h-full auto-cols-[100%] grid-flow-col">
              <div className="absolute inset-0 z-10 bg-[radial-gradient(50%_50%_at_50%_50%,rgba(0,0,0,0)_0%,rgba(0,0,0,0.5)_100%)] from-background/10 to-background/10 backdrop-blur-xs" />
              <video
                ref={videoRef}
                className="absolute inset-0 z-0 size-full object-cover"
                poster={HeaderPosterImage.src}
                disablePictureInPicture
                disableRemotePlayback
                muted
                playsInline
                autoPlay
                loop
              >
                <source type="video/webm" src={`${Config.PUBLIC_ASSETS_BASE_URL}/${VIDEO_PATH_WEBM}`} />
                <source type="video/mp4" src={`${Config.PUBLIC_ASSETS_BASE_URL}/${VIDEO_PATH_MP4}`} />
              </video>
            </div>
            <div className="absolute inset-0 z-10 flex flex-col items-center justify-center">
              <div className="grid px-4">
                <Heading
                  size="h1"
                  textAlign={isSmallScreen ? "left" : "center"}
                  weight="black"
                  className={cn(
                    "mb-4 drop-shadow-sm",
                    isKorean ? "font-kr" : "font-heading",
                    isSmallScreen ? "text-[40px] leading-[1.2]" : "text-[80px] leading-[1.04]"
                  )}
                >
                  {isKorean ? "무한한 가능성의" : "Interactive Media,"}
                  <br />
                  {isKorean ? "뉴미디어" : "Limitless Possibilities"}
                </Heading>
                <Text
                  size={isSmallScreen ? "sm" : "lg"}
                  textAlign={isSmallScreen ? "left" : "center"}
                  className={isKorean ? "font-kr" : "font-body"}
                >
                  {isKorean
                    ? "공간 컴퓨팅과 AI가 만나 새로운 차원의 몰입감 넘치는 세상을 만들어 냅니다."
                    : "Harness Spatial Computing and AI to unlock the next generation of interactive virtual worlds"}
                </Text>
                <div
                  className={cn(
                    "mt-12 flex items-center gap-6 justify-self-center",
                    isSmallScreen && "mt-6 gap-4 justify-self-start"
                  )}
                >
                  <Button
                    size={isSmallScreen ? "lg" : "xl"}
                    className={cn(
                      "relative w-60 overflow-hidden bg-gradient-to-r from-[#00CCFF] to-[#0033FF] text-lg text-white shadow-none hover:scale-100 sm:!shadow-[0_4px_32px_4px_rgba(0,102,255,.6)]",
                      isSmallScreen && "text-md w-[170px]"
                    )}
                    onClick={showOldHomepage}
                  >
                    <span className={cn("pointer-events-none z-10", isKorean && "font-kr font-bold")}>
                      {isKorean ? "시작하기" : "Get started"}
                    </span>
                    <div className="absolute right-0 top-0 size-full bg-[#0044ff] opacity-0 transition-opacity duration-500 hover:opacity-100" />
                  </Button>
                  <Button
                    size={isSmallScreen ? "lg" : "xl"}
                    variant="text"
                    as={Link}
                    href="https://spatialxr.typeform.com/enterprise"
                    target="_blank"
                    color="white"
                    leftIcon={<EmailIcon className={cn("icon icon-md", isSmallScreen && "icon-sm")} />}
                    className={cn("w-fit px-0 text-lg", isSmallScreen && "text-md", isKorean && "font-kr font-bold")}
                    onClick={() => {
                      trackInteraction({
                        type: InteractionType.Click,
                        name: InteractionName.HomepageHeaderContactSales,
                      })
                    }}
                  >
                    {isKorean ? "협업 문의" : "Contact Sales"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </TrackedComponent>
      </section>
    )
  })
)
