/**
 * The styling and implementation of this component
 * is adapted heavily from https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/skeleton/src/skeleton.tsx
 */

"use client"

import { Slot } from "@radix-ui/react-slot"
import { VariantProps, cva } from "class-variance-authority"
import { forwardRef, useEffect } from "react"
import { usePrevious } from "react-use"

import { useBoolean } from "@spatialsys/react/hooks/use-boolean"

import { BaseComponentProps } from "../types"
import { cn } from "../utils/cn"

const skeletonVariants = cva(
  cn(
    "pointer-events-none select-none rounded bg-muted bg-clip-padding",
    // We set color:transparent and visibility: hidden to avoid children being visible through the Skeleton since the bg is translucent
    "text-transparent before:invisible after:invisible [&_*]:invisible"
  ),
  {
    variants: {
      animate: {
        pulse: "animate-pulse",
        none: "",
      },
    },
    defaultVariants: { animate: "pulse" },
  }
)

export type SkeletonProps = VariantProps<typeof skeletonVariants> &
  BaseComponentProps & {
    /**
     * If true, the Skeleton will render its children with a fade transition
     *
     * @default false
     */
    isLoaded?: boolean
  }

/**
 * Displays a placeholder while content is loading.
 *
 * You can use it as a standalone component:
 *
 * ```
 * <Skeleton className="h-12 w-40" />
 * ```
 *
 * Or, you can also wrap another component to take the same width and height.
 * ```
 * <Skeleton>Lorem ipsum dolor</Skeleton>
 * ```
 *
 * @see https://web-docs.spatial.io/components/skeleton
 */
export const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>((props, ref) => {
  const { asChild, className, isLoaded, animate, ...rest } = props

  const [isFirstRender, setIsFirstRender] = useBoolean(true)
  useEffect(() => {
    setIsFirstRender.setFalse()
  }, [setIsFirstRender])

  const wasPreviouslyLoaded = usePrevious(isLoaded)

  const Comp = asChild ? Slot : "div"

  if (isLoaded) {
    return (
      <Comp
        ref={ref}
        className={cn(
          isFirstRender || wasPreviouslyLoaded
            ? "animate-none"
            : "duration-300 animate-in fade-in running direction-normal",
          className
        )}
        {...rest}
      />
    )
  }

  return <Comp ref={ref} className={cn(skeletonVariants({ animate }), className)} {...rest} />
})

Skeleton.displayName = "Skeleton"
