import dynamic from "next/dynamic"
import { Fragment, memo, useMemo } from "react"

import { FeedComponentData, FeedComponentType } from "@spatialsys/js/sapi/types"
import { TrackedComponent, TrackedComponents } from "@spatialsys/react/analytics"
import { useGetFeatureFlagsQuery } from "@spatialsys/react/query-hooks/feature-flags"
import { useGetFeedV2Query } from "@spatialsys/react/query-hooks/spaces"
import { useAuthState } from "@spatialsys/web/app-context"
import { GenericError } from "@spatialsys/web/core/js/components/generic-error/generic-error"
import { SpacesListGridLoadingSkeleton } from "@spatialsys/web/core/js/components/spaces-list-grid/loading-skeleton/spaces-list-grid-loading-skeleton"

import { VideoPlayingContextProvider } from "../video-playing-context"

type FeedComponent = (props: FeedComponentData & { sectionIndex: number }) => JSX.Element

const SpacesGrid = dynamic(() =>
  import(/* webpackChunkName: "feed-spaces-grid" */ "./spaces-grid/spaces-grid").then((mod) => mod.SpacesGrid)
) as FeedComponent

const FriendsHomeFeedList = dynamic(() =>
  import("../../components/friends/components/friends-home-feed-list/friends-home-feed-list").then(
    (mod) => mod.FriendsHomeFeedList
  )
)
const MoreGamesHomeFeed = dynamic(() =>
  import("../../components/more-games/more-games-home-feed").then((mod) => mod.MoreGamesHomeFeed)
)

const SpacesHighlightVideos = dynamic(() =>
  import(
    /* webpackChunkName: "feed-spaces-highlight-videos" */ "./spaces-highlight-videos/spaces-highlight-videos"
  ).then((mod) => mod.SpacesHighlightVideos)
) as FeedComponent

const feedComponentsMap: Record<FeedComponentType, FeedComponent> = {
  [FeedComponentType.SpacesGrid]: SpacesGrid,
  [FeedComponentType.SpacesRow]: SpacesGrid,
  [FeedComponentType.SpacesVideoHighlights]: SpacesHighlightVideos,
}

type SpacesFeedProps = { userAgent: string }

/**
 * Renders the list of feed items
 */
export const SpacesFeed = memo(function SpacesFeedList({ userAgent }: SpacesFeedProps) {
  const getFeedQuery = useGetFeedV2Query({ userAgent })

  return (
    <TrackedComponent as="div" id={TrackedComponents.SpacesFeed} className="flex flex-col gap-6 md:gap-8">
      <SpacesFeedInner feedQuery={getFeedQuery} />
    </TrackedComponent>
  )
})

type SpacesFeedInnerProps = {
  feedQuery: ReturnType<typeof useGetFeedV2Query>
}

export const SpacesFeedInner = memo(function SpacesFeedInner({ feedQuery }: SpacesFeedInnerProps) {
  const getFeatureFlagsQuery = useGetFeatureFlagsQuery()
  const { isLoggedIn } = useAuthState()

  const thirdPartyGamesEnabled = getFeatureFlagsQuery.data?.featureFlags.thirdPartyGames
  const hideThirdPartyGamesInHomeFeed = getFeatureFlagsQuery.data?.featureFlags.hideThirdPartyGamesInHomeFeed
  const thirdPartyGamesFeedPosition = getFeatureFlagsQuery.data?.featureFlags.thirdPartyGamesFeedPosition
  const showThirdPartyGamesInFeed = useMemo(() => {
    if (hideThirdPartyGamesInHomeFeed || !thirdPartyGamesEnabled || !feedQuery.data || !thirdPartyGamesFeedPosition)
      return false
    return thirdPartyGamesFeedPosition >= 0 && feedQuery.data.feedItems.length >= thirdPartyGamesFeedPosition
  }, [hideThirdPartyGamesInHomeFeed, thirdPartyGamesEnabled, feedQuery.data, thirdPartyGamesFeedPosition])

  if (feedQuery.data) {
    const FirstSpacesRow = feedComponentsMap[feedQuery.data.feedItems[0].type]
    return (
      <VideoPlayingContextProvider>
        <div className="flex flex-col gap-6 md:gap-8">
          <FirstSpacesRow sectionIndex={0} {...feedQuery.data.feedItems[0].data} />
          {isLoggedIn && <FriendsHomeFeedList />}

          {feedQuery.data.feedItems.map((item, i) => {
            if (i === 0) return null
            const Component = feedComponentsMap[item.type]

            // Show third party games in specific position in the feed based on flag
            if (showThirdPartyGamesInFeed && i === thirdPartyGamesFeedPosition) {
              return (
                <Fragment key={i}>
                  <MoreGamesHomeFeed sectionIndex={i} />
                  <Component sectionIndex={i + 1} {...item.data} />
                </Fragment>
              )
            } else {
              const sectionIndex =
                showThirdPartyGamesInFeed && thirdPartyGamesFeedPosition && i > thirdPartyGamesFeedPosition ? i + 1 : i
              return <Component key={i} sectionIndex={sectionIndex} {...item.data} />
            }
          })}

          {thirdPartyGamesEnabled && !showThirdPartyGamesInFeed && !hideThirdPartyGamesInHomeFeed && (
            <MoreGamesHomeFeed sectionIndex={feedQuery.data.feedItems.length} />
          )}
        </div>
      </VideoPlayingContextProvider>
    )
  }

  if (feedQuery.error) {
    return <GenericError className="h-[50vh]" />
  }
  return <SpacesListGridLoadingSkeleton />
})
