import { m } from "framer-motion"
import Link from "next/link"
import { memo, useCallback, useState } from "react"
import Balancer from "react-wrap-balancer"

import AriFull from "@spatialsys/assets/img/rebranding/avatar-customization/ari-full.webp"
import Ari from "@spatialsys/assets/img/rebranding/avatar-customization/ari.webp"
import Blox from "@spatialsys/assets/img/rebranding/avatar-customization/blox.webp"
import BotFull from "@spatialsys/assets/img/rebranding/avatar-customization/bot-full.webp"
import Bot from "@spatialsys/assets/img/rebranding/avatar-customization/bot.webp"
import Glasses from "@spatialsys/assets/img/rebranding/avatar-customization/glasses.webp"
import Helmet from "@spatialsys/assets/img/rebranding/avatar-customization/helmet.webp"
import Hoodies from "@spatialsys/assets/img/rebranding/avatar-customization/hoodies.webp"
import Jumper from "@spatialsys/assets/img/rebranding/avatar-customization/jumper.webp"
import Jumppack from "@spatialsys/assets/img/rebranding/avatar-customization/jumppack.webp"
import Jumpsuit from "@spatialsys/assets/img/rebranding/avatar-customization/jumpsuit.webp"
import Mask from "@spatialsys/assets/img/rebranding/avatar-customization/mask.webp"
import RpmFull from "@spatialsys/assets/img/rebranding/avatar-customization/rpm-full.webp"
import Rpm from "@spatialsys/assets/img/rebranding/avatar-customization/rpm.webp"
import { Button, cn } from "@spatialsys/web/ui"
import { Heading } from "@spatialsys/web/ui/heading"

import { useLocale } from "../../homepage"
import { SectionHeader } from "../../layouts/marketing/section/section-header"

const AVATAR_ATTACHMENTS = [
  {
    name: "Mask",
    image: Mask,
    x: 30,
    y: -10,
    rotation: 0,
  },
  {
    name: "Glasses",
    image: Glasses,
    x: 48,
    y: 20,
    rotation: 13,
  },
  {
    name: "Jumppack1",
    image: Jumppack,
    x: 73,
    y: 0,
    rotation: 0,
  },
  {
    name: "Hoodies",
    image: Hoodies,
    x: 91,
    y: 25,
    rotation: 0,
  },
  {
    name: "Jumper",
    image: Jumper,
    x: 38,
    y: 50,
    rotation: 15,
  },
  {
    name: "Helmet1",
    image: Helmet,
    x: 76,
    y: 45,
    rotation: 0,
  },
  {
    name: "Helmet2",
    image: Helmet,
    x: 110,
    y: 58,
    rotation: 0,
  },
  {
    name: "Blox",
    image: Blox,
    x: 16,
    y: 79,
    rotation: 10,
  },
  {
    name: "Jumppack2",
    image: Jumppack,
    x: 54,
    y: 70,
    rotation: 0,
  },
  {
    name: "Jumpsuit",
    image: Jumpsuit,
    x: 88,
    y: 74,
    rotation: 0,
  },
]

type AvatarCustomizationProps = {
  isSmallScreen: boolean
}

type AvatarType = "rpm" | "custom" | "bot"

export const AvatarCustomization = memo(function AvatarCustomization({ isSmallScreen }: AvatarCustomizationProps) {
  const locale = useLocale()
  const isKorean = locale === "ko" || locale === "ko-KR"

  const [selectedAvatar, setSelectedAvatar] = useState<AvatarType>("rpm")

  const getAvatarImage = useCallback(() => {
    switch (selectedAvatar) {
      case "rpm":
        return RpmFull.src
      case "custom":
        return AriFull.src
      case "bot":
        return BotFull.src
    }
  }, [selectedAvatar])

  return (
    <div className="relative grid aspect-[1.5/1] max-h-[1107px] w-full grid-cols-1 grid-rows-[auto_1fr] sm:max-h-[960px] sm:grid-cols-2 sm:grid-rows-1">
      <div className="z-10 grid items-center px-4 sm:pl-6 md:pl-12 lg:pl-[120px]">
        <div className="flex flex-col gap-6">
          <SectionHeader
            title={isKorean ? "아바타 만들기" : "Avatar Customization"}
            titleClassName={isKorean ? "font-kr font-bold" : undefined}
            catchphrase={
              <>
                {isKorean ? "다양한 캐릭터" : "Infinite Characters,"}
                <br />
                {isKorean ? "끝없는 가능성" : "Endless Connections"}
              </>
            }
            description={
              isKorean ? (
                <>
                  <Balancer>수천 가지의 Ready Player Me 캐릭터를 아바타로 선택하거나,</Balancer>{" "}
                  <Balancer>Unity SDK를 활용하여 커스텀 아바타 제작과</Balancer>{" "}
                  <Balancer>생동감 있는 AI 챗 기능을 구현하세요.</Balancer>{" "}
                  <Balancer>음성, 텍스트, 영상 채팅 등으로 다른 플레이어들과의 소통을 시작해보세요.</Balancer>
                </>
              ) : (
                "Choose from thousands of Ready Player Me customizations or bring your characters and AI chatbots to life with our Unity SDK and APIs. Connect instantly via voice, text, or video chat."
              )
            }
            catchphraseClassName={cn("text-[28px] md:text-[56px] md:leading-[1.1]", isKorean && "font-kr font-bold")}
            descriptionClassName={cn("md:text-md lg:text-lg", isKorean && "font-kr")}
            align="left"
            noPadding
          />
          <div className="flex gap-[18px]">
            <button className="group grid gap-[10px]" onClick={() => setSelectedAvatar("rpm")}>
              <div
                className={cn(
                  "size-20 overflow-hidden rounded-full border border-transparent-white/20 bg-background transition-colors group-hover:border-blue group-hover:shadow-[0_0_10px_rgba(0,128,255,0.3)] sm:size-[100px]",
                  selectedAvatar === "rpm" && "border-blue shadow-[0_0_10px_rgba(0,128,255,0.3)]"
                )}
              >
                <img
                  src={Rpm.src}
                  alt="RPM Avatar"
                  className="size-full transition-transform group-hover:scale-105"
                  draggable="false"
                />
              </div>
              <Heading className={cn("text-xs", isKorean && "font-kr font-bold")}>
                RPM {isKorean ? "아바타" : "Avatar"}
              </Heading>
            </button>
            <button className="group grid gap-[10px]" onClick={() => setSelectedAvatar("custom")}>
              <div
                className={cn(
                  "size-20 overflow-hidden rounded-full border border-transparent-white/20 bg-background transition-colors group-hover:border-blue group-hover:shadow-[0_0_10px_rgba(0,128,255,0.3)] sm:size-[100px]",
                  selectedAvatar === "custom" && "border-blue shadow-[0_0_10px_rgba(0,128,255,0.3)]"
                )}
              >
                <img
                  src={Ari.src}
                  alt="Custom Avatar"
                  className="size-full transition-transform group-hover:scale-105"
                  draggable="false"
                />
              </div>
              <Heading className={cn("text-xs", isKorean && "font-kr font-bold")}>
                {isKorean ? "커스텀 아바타" : "Custom Avatar"}
              </Heading>
            </button>
            <button className="group grid gap-[10px]" onClick={() => setSelectedAvatar("bot")}>
              <div
                className={cn(
                  "size-20 overflow-hidden rounded-full border border-transparent-white/20 bg-background transition-colors group-hover:border-blue group-hover:shadow-[0_0_10px_rgba(0,128,255,0.3)] sm:size-[100px]",
                  selectedAvatar === "bot" && "border-blue shadow-[0_0_10px_rgba(0,128,255,0.3)]"
                )}
              >
                <img
                  src={Bot.src}
                  alt="AI Chatbot"
                  className="size-full transition-transform group-hover:scale-105"
                  draggable="false"
                />
              </div>
              <Heading className={cn("text-xs", isKorean && "font-kr font-bold")}>
                {isKorean ? "AI 챗봇" : "AI Chatbot"}
              </Heading>
            </button>
          </div>
          <Button
            size={isSmallScreen ? "lg" : "xl"}
            className={cn(
              "mt-4 w-full bg-popover text-sm sm:mt-8 sm:w-80 sm:text-[18px]",
              isKorean && "font-kr font-bold"
            )}
            noShadow
            as={Link}
            href="/store"
            noScaleOnHover={isSmallScreen}
          >
            {isKorean ? "내 아바타 만들기" : "Create my avatar"}
          </Button>
        </div>
      </div>
      <div className="relative h-[700px] w-full overflow-hidden sm:absolute sm:inset-0 sm:h-full">
        <m.div
          key={selectedAvatar}
          className="absolute right-1/2 top-1/2 z-10 h-[85%] min-w-96 -translate-y-1/2 sm:right-[8%] lg:right-[14%] xl:right-[18%] 2xl:right-[22%]"
          initial={{ opacity: 0, y: "-40%", x: isSmallScreen ? "50%" : 0 }}
          exit={{ opacity: 0, y: "-40%", x: isSmallScreen ? "50%" : 0 }}
          transition={{ duration: 0.6 }}
          whileInView={{ opacity: 1, y: "-50%", x: isSmallScreen ? "50%" : 0 }}
          viewport={{ once: true, margin: "0px 0px -180px 0px" }}
        >
          <img src={getAvatarImage()} alt={`${selectedAvatar} Avatar`} className="size-full object-contain" />
        </m.div>
        {/* Background grid pattern */}
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 text-transparent-white/15">
            <svg aria-hidden="true" className="size-full">
              <defs>
                <pattern
                  id=":S2:"
                  width="56"
                  height="65"
                  patternUnits="userSpaceOnUse"
                  x="100%"
                  y="100%"
                  patternTransform="translate(112 72)"
                >
                  <path d="M0 128V.5H128" fill="none" stroke="currentColor"></path>
                </pattern>
              </defs>
              <rect width="100%" height="100%" fill="url(#:S2:)"></rect>
            </svg>
          </div>
        </div>
        {/* Avatar attachment bubbles */}
        {AVATAR_ATTACHMENTS.map((attachment) => (
          <AvatarAttachmentBubble
            key={attachment.name}
            name={attachment.name}
            x={attachment.x}
            y={attachment.y}
            rotation={attachment.rotation}
            imageUrl={attachment.image.src}
            isSmallScreen={isSmallScreen}
          />
        ))}
        <div className="absolute inset-0 bg-gradient-to-t from-background from-5% via-transparent via-50% to-background to-95%" />
      </div>
    </div>
  )
})

const AvatarAttachmentBubble = memo(function AvatarAttachmentBubble({
  name,
  x,
  y,
  rotation,
  imageUrl,
  isSmallScreen,
}: {
  name: string
  x: number
  y: number
  imageUrl: string
  rotation: number
  isSmallScreen: boolean
}) {
  return (
    <div
      className="absolute flex aspect-square w-[17.777%] min-w-32 max-w-64 items-center justify-center overflow-hidden rounded-full border border-transparent-white/20 bg-background p-[1.77%]"
      style={{
        top: `${y}%`,
        left: `${x}%`,
        transform: isSmallScreen ? `translateX(-100%) rotate(${rotation}deg)` : `rotate(${rotation}deg)`,
      }}
    >
      <img src={imageUrl} alt={name} className="size-full object-contain" />
    </div>
  )
})
