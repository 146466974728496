import { memo } from "react"

import { ReactComponent as HeartIcon } from "@spatialsys/assets/icons/lucide/heart.svg"
import { cn } from "@spatialsys/web/ui"

type LoveSpaceIconProps = {
  className?: string
  isDisabled?: boolean
  isLoved?: boolean
  loveCount: number
}

/**
 * Renders the heart icon based on whether the space is loved by the user
 * Applies hover/active affects using `group` tailwind selector
 */
export const LoveSpaceIcon = memo(function LoveSpaceIcon(props: LoveSpaceIconProps) {
  const { className, isDisabled, isLoved, loveCount } = props

  return (
    <HeartIcon
      className={cn(
        // set `fill-transparent` so that transition works.
        //  An initial value for fill is required (i.e. not fill="none") in order to transition the fill color
        "icon fill-transparent transition-all duration-200",
        !isDisabled && "group-active:scale-125",
        loveCount > 0 && isLoved && "fill-red text-red",
        !isLoved && !isDisabled && "group-hover:fill-white",
        className
      )}
    />
  )
})
