import { memo } from "react"
import Balancer from "react-wrap-balancer"

import { screensPx } from "@spatialsys/theme/screens"
import { Container, Heading, Text, cn } from "@spatialsys/web/ui"
import { useWindowSize } from "@spatialsys/web/ui/hooks"

type SectionHeaderProps = {
  title: string
  catchphrase: string | React.ReactNode
  description?: string | React.ReactNode
  titleClassName?: string
  catchphraseClassName?: string
  descriptionClassName?: string
  align?: "left" | "center"
  noPadding?: boolean
}

export const SectionHeader = memo(function SectionHeader({
  title,
  catchphrase,
  description,
  titleClassName,
  catchphraseClassName,
  descriptionClassName,
  align = "center",
  noPadding = false,
}: SectionHeaderProps) {
  const windowSize = useWindowSize()
  const isSmallScreen = Boolean(windowSize.width && windowSize.width <= screensPx.sm)

  return (
    <Container className={noPadding ? "!p-0" : undefined}>
      <Heading
        textAlign={align}
        className={cn("mb-4 text-xl font-demibold text-blue", isSmallScreen && "mb-2 text-sm", titleClassName)}
      >
        {title}
      </Heading>
      <Heading
        size="h1"
        weight="bold"
        textAlign={align}
        className={cn("mb-[10px]", isSmallScreen && "mb-1 text-xl", catchphraseClassName)}
      >
        <Balancer>{catchphrase}</Balancer>
      </Heading>
      <Text
        size="lg"
        textAlign={align}
        className={cn("text-muted-foreground", isSmallScreen && "text-sm", descriptionClassName)}
      >
        <Balancer>{description}</Balancer>
      </Text>
    </Container>
  )
})
