import { Organization, PostalAddress, WithContext } from "schema-dts"

import Config from "@spatialsys/web/config"

const logo = `${Config.PUBLIC_ASSETS_BASE_URL}/favicon.png`

const address: PostalAddress = {
  "@type": "PostalAddress",
  addressCountry: "US",
  addressLocality: "New York",
  addressRegion: "NY",
  telephone: "N/A",
}

export const homePageJsonLd: WithContext<Organization> = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Spatial.io",
  url: "https://spatial.io/",
  description:
    "Spatial is a 3D gaming hub where creators craft and share immersive social experiences for web, mobile, and VR. We're transforming online interaction from passive watching to active play. Think YouTube, but for games.",
  email: "support@spatial.io",
  logo,
  image: logo,
  contactPoint: {
    "@type": "ContactPoint",
    contactType: "User Feedback",
    url: "https://www.spatial.io/contact",
    email: "support@spatial.io",
  },
  address: address,
  location: address,
  founders: [
    {
      "@type": "Person",
      name: "Anand Agarawala",
      gender: "Male",
      jobTitle: "Co-founder & CEO",
      sameAs: [
        "https://www.linkedin.com/in/anandx/",
        "https://www.crunchbase.com/person/anand-agarawala",
        "https://twitter.com/anandx",
      ],
    },
    {
      "@type": "Person",
      name: "Jinha Lee",
      gender: "Male",
      jobTitle: "Co-founder & Chief Product Officer",
      sameAs: [
        "https://www.linkedin.com/in/leejinha/",
        "http://www.leejinha.com/",
        "https://twitter.com/jinha_io",
        "https://www.crunchbase.com/person/jinha-lee-fa54",
      ],
    },
  ],
  foundingDate: "2017",
  sameAs: [
    "https://www.linkedin.com/company/spatial-systems",
    "https://twitter.com/Spatial_io",
    "https://play.google.com/store/apps/details?id=io.spatial.spatial",
    "https://apps.apple.com/us/app/spatial/id1528403747",
    "https://www.instagram.com/spatial_io/",
    "https://www.tiktok.com/@spatial_io",
    "https://www.youtube.com/channel/UC1kHcWiYGmqTlZmppBdqsYQ",
    "https://www.facebook.com/spatial.io/",
    "https://linkin.bio/spatial_io",
  ],
  legalName: "Spatial Systems Inc",
  telephone: "N/A",
}
